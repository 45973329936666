import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';

const CustomTypewriter = ({ content }) => {
  const [displayedContent, setDisplayedContent] = useState('');
  const currentIndexRef = useRef(0);
  const timeoutRef = useRef(null);
  const animationFrameRef = useRef(null);
  const lastUpdateTimeRef = useRef(Date.now());
  const chunkSizeRef = useRef(2);
  const typingSpeedRef = useRef(5); // ms between updates

  useEffect(() => {
    if (!content) return;

    const animate = () => {
      const now = Date.now();
      const elapsed = now - lastUpdateTimeRef.current;

      if (elapsed >= typingSpeedRef.current && currentIndexRef.current < content.length) {
        const nextIndex = Math.min(
          currentIndexRef.current + chunkSizeRef.current,
          content.length
        );
        
        setDisplayedContent(content.slice(0, nextIndex));
        currentIndexRef.current = nextIndex;
        lastUpdateTimeRef.current = now;
      }

      if (currentIndexRef.current < content.length) {
        animationFrameRef.current = requestAnimationFrame(animate);
      }
    };

    // Reset if content has changed completely
    if (!content.startsWith(displayedContent)) {
      currentIndexRef.current = 0;
      setDisplayedContent('');
    }

    // Start animation
    animationFrameRef.current = requestAnimationFrame(animate);

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [content, displayedContent]);

  return (
    <div className="whitespace-pre-wrap">
      <ReactMarkdown>{displayedContent}</ReactMarkdown>
    </div>
  );
};

export default CustomTypewriter;
