import {
  Box,
  Typography,
} from "@mui/material";
import React from "react";


const DrawerChatsList = ({ chatList, onChatItemClick, onChatDelete }) => {
  // Utility function to capitalize the first letter of each word
  const capitalizeFirstLetter = (str) => {
    return str
      .split(/[\s-]/) // Split by spaces or hyphens
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" "); // Join with spaces
  };

  return (
    <Box className="flex flex-col gap-[2px]">
      {chatList.map((item) => (
        <Box
          key={item.id}
          className="flex items-center mx-2 justify-between my-1 px-3 py-3 text-white bg-[#222D44] border-[#39445B] border-[0.8px] rounded-lg cursor-pointer text-nowrap"
          onClick={() => onChatItemClick(item.id)}
        >
          <Typography className="!text-[10px] lg:!text-[14px] !font-normal truncate ">
            {capitalizeFirstLetter(item.title)} {/* Apply the function here */}
          </Typography>
          <div
            fontSize="small"
            className="cursor-pointer ml-2 text-[#d6d6d6]"
            onClick={(e) => {
              e.stopPropagation();
              onChatDelete(item.id);
            }}
          ><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.95825 12.875C2.56867 12.875 2.23516 12.7363 1.95773 12.4589C1.6803 12.1814 1.54159 11.8479 1.54159 11.4583V2.25H0.833252V0.833333H4.37492V0.125H8.62492V0.833333H12.1666V2.25H11.4583V11.4583C11.4583 11.8479 11.3195 12.1814 11.0421 12.4589C10.7647 12.7363 10.4312 12.875 10.0416 12.875H2.95825ZM10.0416 2.25H2.95825V11.4583H10.0416V2.25ZM4.37492 10.0417H5.79159V3.66667H4.37492V10.0417ZM7.20825 10.0417H8.62492V3.66667H7.20825V10.0417Z" fill="#E4E4E4" />
            </svg> </div>
        </Box>
      ))}
    </Box>
  );
};

export default DrawerChatsList;