import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Eye, EyeOff } from "lucide-react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { signUpValidationSchema } from "../utils/validationSchema";
import { generateOTP } from "../utils/generateOTP";
import useFetch from "../api/HttpRequest";
import AuthButtons from "../components/authButtons";
import OTPModal from "../components/OTPModal";
import Logo from "../assets/image.png";
import routeConstant from "../utils/routeConstant";

const SignUp = () => {
  const [currentOTP, setCurrentOTP] = useState();
  const [isOTPModalOpen, setIsOTPModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isRegistered, setIsRegistered] = useState(false);

  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(signUpValidationSchema),
  });

  const { data: registerResponse, fetchData: registerFetch } = useFetch('/user/register', {
    method: 'POST',
    data: null,
    silent: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    setError(null);
    const OTP = generateOTP();
    setCurrentOTP(OTP);
    const payload = {
      firstName: data.name,
      email: data.email,
      password: data.password,
    };
    try {
      await registerFetch({ data: payload });
    } catch (err) {
      console.error("An error occurred during signup:", err);
      setError("An error occurred during signup. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (registerResponse?.status) {
      setIsRegistered(true);
    }
  }, [registerResponse]);

  return (
    <div className="background2">
    <div className="min-h-screen  flex flex-col items-center justify-center py-10 px-4">
       {/* Logo */}
       <Link to={routeConstant.dashboard}>
          <img src={Logo} alt="Evatt.AI" className={` ${isRegistered ? "w-[306px] h-auto" :" w-[102px] h-[20px]"}`} />
        </Link>


      {isRegistered ? (
        <p className="text-2xl font-bold text-green-500 text-center mt-8 max-w-2xl">
          Successfully registered, check your email inbox to activate your account.
        </p>
      ) : (
        <div className="w-full max-w-md space-y-6 mt-5">
          <div className="space-y-2">
            <h1 className="text-2xl font-semibold flex items-center justify-center text-white">Create Account</h1>
            <p className="text-sm flex justify-center items-center text-white">
              Let's get started by filling out the form below.
            </p>
          </div>

          <div className="flex flex-col gap-4">
           {/* Google Sign In */}
           <button className="w-full bg-white text-gray-700 rounded-lg py-3 px-4 mb-6 flex items-start justify-center gap-2 hover:bg-gray-50 transition-colors">
            <AuthButtons type="Google" mode="signin" />
          </button>

            <div className="relative">
              {/* <div className="absolute inset-0 flex items-center">
                <span className="w-full border-t border-gray-700" />
              </div> */}
              <div className="relative flex justify-center text-xs ">
                <span className=" px-2 text-white text-[16px]">or</span>
              </div>
            </div>

            <div className="space-y-6">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-white  mb-2">Name</label>
                <input
                  id="name"
                  type="text"
                  placeholder="Enter Your Name"
                  {...register("name")}
                  className={` w-full  border-[1px] bg-[#FFFFFF0A] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E]
                    ${errors?.name ? 'border-yellow-500' : ''}`}
                />
                {errors?.name && (
                  <p className="text-yellow-500 text-sm mt-1">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-white  mb-2" >Email address</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Email address"
                  {...register("email")}
                  className={` w-full  border-[1px] bg-[#FFFFFF0A] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E]
                    ${errors?.email ? 'border-yellow-500' : ''}`}
                />
                {errors?.email && (
                  <p className="text-yellow-500 text-sm mt-1">{errors.email.message}</p>
                )}
              </div>

              <div className="relative">
                <label htmlFor="password" className="block text-sm font-medium text-white  mb-2">Password</label>
                
                <input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  {...register("password")}
                  className={` w-full  border-[1px] bg-[#FFFFFF0A] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E]
                    ${errors?.password ? 'border-yellow-500' : ''}`}
                />
              <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-300 focus:outline-none mt-4"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              
               
              </div>
              {errors?.password && (
                  <p className="text-yellow-500 text-sm mt-1">{errors.password.message}</p>
                )}
            </div>

            <button
              onClick={handleSubmit(onSubmit)}
              disabled={loading}
              className="w-full bg-[#00A67E] mt-4 hover:bg-[#008F6B] text-white py-3 px-4 rounded-lg transition duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? "Registering..." : "Register"}
            </button>

            {error && (
              <p className="text-yellow-500 text-sm text-center">{error}</p>
            )}

            <p className="text-[16px] font-medium text-white mt-3 text-center">
              Already have an account?{" "}
              <Link to="/signin" className="text-[#00A67E] underline hover:underline">
                Sign in
              </Link>
            </p>
          </div>

          <OTPModal
            userDetails={getValues()}
            isOTPModalOpen={isOTPModalOpen}
            setIsOTPModalOpen={setIsOTPModalOpen}
            currentOTP={currentOTP}
            setCurrentOTP={setCurrentOTP}
          />
        </div>
      )}
    </div>
    </div>
  );
};

export default SignUp;