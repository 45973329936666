import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../providers/userProvider";
import Cookies from 'js-cookie';
import useFetch from "../api/HttpRequest";
import routeConstant from "../utils/routeConstant";
import AuthButtons from "../components/authButtons";
import Logo from "../assets/image.png";

const SignIn = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const user = useContext(UserContext);
  const navigate = useNavigate();

  const { data: loginData, fetchData: loginFetch } = useFetch('/user/login', {
    method: 'POST',
    data: formData,
    silent: false,
    successMessage: 'Welcome to Evatt AI',
  });

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  useEffect(() => {
    if (loginData?.status) {
      setTimeout(() => {
        const accessToken = loginData.data.accessToken;
        Cookies.set('accessToken', accessToken);
        window.location.href = routeConstant?.dashboard;
      }, 2000)
    }
  }, [loginData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.email || !formData.password) {
      setError("Please enter both email and password.");
      return;
    }
    setLoading(true);
    setError(null);
    try {
      await loginFetch();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="background2">
    <div className="min-h-screen   flex flex-col items-center justify-center py-10 px-4">
      <div className="max-w-md  w-full mx-auto flex flex-col items-center gap-6">
        {/* Logo */}
        <Link to={routeConstant.dashboard}>
          <img src={Logo} alt="Evatt.AI" className="w-[102px] h-[20px]" />
        </Link>

        <div className="w-full">
          {/* Header Text */}
          <h1 className="text-[18px] font-semibold text-white text-center mb-2">
          Log in to Evatt AI
          </h1>
          <p className="text-white text-center mb-8">
          Evatt AI Making Lawyers' Lives Easier using AI software.
          </p>

          {/* Google Sign In */}
          <button className="w-full bg-white text-gray-700 rounded-lg py-3 px-4 mb-6 flex items-start justify-center gap-2 hover:bg-gray-50 transition-colors">
            <AuthButtons type="Google" mode="signin" />
          </button>

          <div className="flex items-center justify-center gap-4 mb-6">
            {/* <div className="flex-1 h-px bg-gray-700"></div> */}
            <span className="text-white">or</span>
            {/* <div className="flex-1 h-px bg-gray-700"></div> */}
          </div>

          {/* Email Input */}
          <div className="mb-4">
            <label className="block text-white text-sm mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Email address"
              className={`w-full  border-[1px] bg-[#FFFFFF0A] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E] ${!formData.email && error ? 'border-yellow-500' : ''}`}
            />
          </div>

          {/* Password Input */}
          <div className="mb-6">
            <label className="block text-white mb-2">Password</label>
            <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleInputChange}
                placeholder="Enter your password"
                className={`w-full bg-[#FFFFFF0A] border-[1px] border-[#D0D5DD] rounded-lg py-3 px-4 text-white placeholder-gray-500 focus:outline-none focus:border-[#00A67E] ${!formData.password && error ? 'border-yellow-500' : ''}`}
              />
              <button
                type="button"
                onClick={() => setShowPassword(!showPassword)}
                className="absolute right-3 top-1/2 -translate-y-1/2"
              >
                <svg
                  className="w-5 h-5 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  {showPassword ? (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                  ) : (
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  )}
                </svg>
              </button>
            </div>
            <div className="flex justify-end mt-1">
              <Link to="/forget-password" className="text-sm text-white hover:text-[#00A67E]">
                Forget password?
              </Link>
            </div>
          </div>

          {/* Login Button */}
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="w-full bg-[#00A068] text-white rounded-lg py-3  font-medium hover:bg-[#008c6a] transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? "Signing In..." : "Login"}
          </button>

          {/* Error Message */}
          {error && (
            <p className="text-yellow-500 text-sm text-center mt-4">{error}</p>
          )}

          {/* Sign Up Link */}
          <p className="text-[16px] font-medium text-white mt-8 text-center">
            Don't have an account?{" "}
            <Link to="/signup" className="text-[#00A67E] underline hover:underline">
              Sign up
            </Link>
          </p>
        </div>
      </div>
    </div>
    </div>
  );
};

export default SignIn;