import { useEffect } from "react";

const CopyFixer = () => {
  useEffect(() => {
    const handleCopy = (event) => {
      const selection = window.getSelection();
      if (!selection) return;

      const text = selection.toString();
      const div = document.createElement("div");
      const range = selection.getRangeAt(0);
      div.appendChild(range.cloneContents());

      // Ensure copied text is black (for Google Docs)
      const copiedHTML = div.innerHTML.replace(/color:\s*white/gi, "color: black");

      event.clipboardData?.setData("text/html", copiedHTML);
      event.clipboardData?.setData("text/plain", text);
      event.preventDefault();
    };

    document.addEventListener("copy", handleCopy);
    return () => document.removeEventListener("copy", handleCopy);
  }, []);

  return null; // No UI, it just runs in the background
};

export default CopyFixer;
