import { Box, TextField } from "@mui/material";
import React, { useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloseIcon from "@mui/icons-material/Close";
import { MoveRight } from 'lucide-react';

const ChatInput = ({
  userQuery,
  setUserQuery,
  onSubmit,
  attachment,
  setAttachment,
  isLoading,
  selectedPdfName,
  setSelectedPdfName,
  setFileSizeWarning,
}) => {
  const MAX_CHARACTERS = 5000;
  const [characterCount, setCharacterCount] = useState(0);
  const [characterError, setCharacterError] = useState(false);

  const handleClose = () => {
    setAttachment(null);
    setUserQuery("");
    setSelectedPdfName("");
    setCharacterCount(0);
    setCharacterError(false);
  };

  const handleChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 5 * 1024 * 1024) {
      setAttachment(null);
      setSelectedPdfName("");
      setFileSizeWarning(false);
      setTimeout(() => setFileSizeWarning(true), 0);
      return;
    }
    setAttachment(file);
    setSelectedPdfName(file.name);
    event.target.value = "";
  };

  const handleQueryChange = (e) => {
    const query = e.target.value;
    setCharacterCount(query.length);
    setCharacterError(query.length > MAX_CHARACTERS);
    setUserQuery(query);
  };

  const handleSubmit = () => {
    if (userQuery.length <= MAX_CHARACTERS && !isLoading) {
      onSubmit(userQuery, attachment);
      handleClose();
    }
  };

  return (
    <Box className="flex flex-col text-white border-1 border-slate-700 -mt-28 md:mt-auto">
      {selectedPdfName && (
        <Box className="flex items-center justify-between px-1 py-1 md:max-w-[533px]  lg:max-w-[615px]   md:py-2 bg-slate-700 rounded-t-3xl">
          <Box className="flex items-center gap-2 max-w-[90%] ">
            <PictureAsPdfIcon fontSize="medium" className="text-white" />
            <span className="!text-[8px] md:!text-sm  !truncate">{selectedPdfName}</span>
          </Box>
          <CloseIcon
            fontSize="small"
            className="text-white cursor-pointer"
            onClick={handleClose}
          />
        </Box>
      )}
      {characterError && (
        <Box className="bg-yellow-500 text-white p-2 text-sm">
          To enhance accuracy, question length must be less than or equal to 5000 characters long.
        </Box>
      )}
      <TextField
        variant="standard"
        margin="normal"
        onChange={handleQueryChange}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey && !isLoading && !characterError) {
            e.preventDefault();
            handleSubmit();
          }
        }}
        value={userQuery}
        fullWidth
        multiline
        maxRows={4}
        disabled={isLoading}
        placeholder="Ask for help with legal research, contract drafting and document review. Be as specific as possible, explaining assumptions and uploading relevant documents."
        className={`!bg-[#1C2436FC] !text-[10px] md:!text-[14px] !overscroll-y-auto relative !min-h-[92px] !h-[92px] !text-white lg:!h-[117px] !rounded-lg [&_.MuiInputBase-input]:custom-scrollbar ${isLoading ? 'opacity-80' : ''}`}
        sx={{
          '& .MuiInputBase-input': {
            minHeight: '60px !important',
            height: '60px !important',
            fontSize: '8px',
            width: '65%',
            '@media (min-width: 1024px)': {
              fontSize: '14px',
              minHeight: '92px !important',
              height: '92px !important',
              width: '75%',
              paddingBottom: '0px !important',
            },
            '@media (min-width: 768px)': {
              fontSize: '10px',
              width: '75%',
              paddingBottom: '34px',
            }
          },
          '& .MuiInputBase-input::-webkit-scrollbar': {
            width: '8px',
          },
          '& .MuiInputBase-input::-webkit-scrollbar-track': {
            background: '#1e293b',
          },
          '& .MuiInputBase-input::-webkit-scrollbar-thumb': {
            background: '#475569',
            borderRadius: '4px',
          },
          '& .MuiInputBase-input::-webkit-scrollbar-thumb:hover': {
            background: '#64748b',
          },
          '& .MuiInputBase-input::placeholder': {
            opacity: '1 !important',
            fontWeight: '400 !important',
          },
        }}
        slotProps={{
          input: {
            startAdornment: (
              <div className="border-white border-r mr-2 !w-8 md:!w-12 !pr-2 mb-[40px] md:mb-[60px]">
                <label className="self-start md:px-4 py-2 cursor-pointer">
                  <AttachFileIcon fontSize="small" className="text-white md:!text-[24px]" />
                  <input
                    type="file"
                    accept=".pdf, .doc, .docx"
                    hidden
                    onChange={handleChange}
                  />
                </label>
              </div>
            ),
            endAdornment: (
              <div className="!w-20 md:!w-28 lg:!w-[154px] xl:!w-[154px] h-12">
                <Box
                  className={`!absolute py-2 px-[3px] md:px-5 pt-[0px] !top-[60px] md:!top-[55px] lg:!top-[60px] !right-0 cursor-pointer !self-end ${(!userQuery || isLoading || characterError) && "opacity-40 pointer-events-none"}`}
                  onClick={handleSubmit}
                >
                  <div className="flex gap-2 text-[10px] lg:text-sm my-auto items-center">
                    <span className="">{characterCount}/5000</span>
                    <div className="border-[1px] flex justify-center items-center w-[16px] h-[16px] lg:w-[36px] lg:h-[36px] rounded-[2px] lg:rounded-lg bg-[#111827]">
                      <MoveRight className="!text-white" />
                    </div>
                  </div>
                </Box>
              </div>
            ),
            disableUnderline: true,
            className: "!py-3 !text-white !font-bold",
          },
        }}
      />
    </Box>
  );
};

export default ChatInput;