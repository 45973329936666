import { useState, useCallback } from 'react';
import axios from 'axios';
import toast from "react-hot-toast";
import Cookies from 'js-cookie';

const BASE_URL = 'https://stg-api.evattai.com/api/v1';
const DEFAULT_ERROR_MESSAGE = 'An unexpected error occurred.';

const useFetch = (endpoint, options = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [responseCode, setResponseCode] = useState(null);

  const fetchData = useCallback(async (overrideOptions = {}) => {
    const { method = 'GET', accessToken, data: requestData, silent = false, successMessage, config, contentType = 'application/json' } = { ...options, ...overrideOptions };

    setLoading(true);
    setError(null);

    try {
      let response;

      const headers = {
        'Authorization': accessToken ? `Bearer ${accessToken}` : undefined,
        ...config?.headers,
      };

      if (method === 'GET') {
        response = await axios.get(`${BASE_URL}${endpoint}`, {
          params: requestData,
          headers,
          ...config,
        });
      } else {
        const finalHeaders = { 'Content-Type': contentType, ...headers };
        const finalData = contentType === 'multipart/form-data' ? requestData : JSON.stringify(requestData);

        response = await axios.post(`${BASE_URL}${endpoint}`, finalData, {
          headers: finalHeaders,
          ...config,
        });
      }

      setResponseCode(response.status);
      const responseData = response.data;

      if (responseData.code) {
        setResponseCode(responseData.code);
      }

      if (responseData.status) {
        setData(responseData);
        if (!silent) {
          toast.success(successMessage || responseData.message);
        }
        return responseData;
      } else {
        if (responseData.code === '401' && endpoint === '/chat/ask/question') {
          window.location.href = "/signin";
        } else if (responseData.code === '403') {
          Cookies.remove('accessToken');
          window.location.href = "/signin";
        } else {
          setData(responseData);
          if (!silent) {
            handleError(responseData.message, responseData.code);
          }
        }
      }
    } catch (error) {
      setResponseCode(error.response?.status || 500);
      handleError(error.message);
    } finally {
      setLoading(false);
    }
  }, [endpoint, options]);

  const handleError = (message = DEFAULT_ERROR_MESSAGE, code) => {
    if (code === 401) {
      Cookies.remove('accessToken');
      window.location.reload();
    } else {
      setError(message);
      toast.error(message || DEFAULT_ERROR_MESSAGE);
    }
  };

  return { data, loading, error, responseCode, fetchData };
};

export default useFetch;
