/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown } from 'lucide-react';
import Australia from '../assets/aus.png';
import UK from '../assets/uk.png';
import USA from '../assets/usa.png';
import India from '../assets/india.png';
import NewZealand from '../assets/newzeland.png';
import Canada from '../assets/canada.png';

const countryImages = {
  AU: Australia,
  GB: UK,
  US: USA,
  IN: India,
  NZ: NewZealand,
  CA: Canada,
};

const CountrySelector = ({ isCollapsed, onSelect, isMobile }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  const COUNTRIES = [
    { id: 1, name: 'Australia', code: 'AU' },
    { id: 2, name: 'UK', code: 'GB' },
    { id: 3, name: 'USA', code: 'US' },
    { id: 4, name: 'India', code: 'IN' },
    { id: 6, name: 'Canada', code: 'CA' },
    { id: 5, name: 'New Zealand', code: 'NZ' },
    
  ];

  const [selectedCountry, setSelectedCountry] = useState(COUNTRIES[0]);

  useEffect(() => {
    if (onSelect && selectedCountry) {
      onSelect(selectedCountry.id);
    }

    function handleClickOutside(event) {
      // Close dropdown if clicking outside dropdown and button
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCountrySelect = (country) => {
    if (country && country.id) {
      setSelectedCountry(country);
      setIsOpen(false);
      onSelect(country.id);
    }
  };

  const currentCountry = COUNTRIES.find((c) => c.id === selectedCountry.id) || COUNTRIES[0];

  const toggleDropdown = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div>
      {/* Button for opening/closing dropdown */}
      <button
        ref={buttonRef}
        onClick={(e) => {
          e.stopPropagation();
          toggleDropdown();
        }}
        className={` lg:!duration-300 xmd:!duration-300 h-[52px] ${isCollapsed || isMobile ? 'w-[75px] text-[25px] md:text-[16px] lg:text-[16px]' : 'md:w-[151px] xmd:w-[281px] text-[16px] lg:w-[300px]'
          } flex items-center justify-center space-x-2 pt-1 mx-auto ${isCollapsed ? 'ml-0 ' : ' md:pl-[8px] xmd:pl-[20px] lg:pl-[8px] lg:pr-[20px] xl:pr-[28px]'} md:mx-auto bg-[#111827] text-white ${
          isMobile ? '' : 'border-t-[3px] border-gray-700 hover:bg-gray-700 focus:outline-none'
        }`}
      >
        <div
          style={{
            borderRadius: '50%',
            overflow: 'hidden',
            width: '1.5em',
            height: '1.5em',
          }}
        >
          <img
            src={countryImages[currentCountry.code]}
            alt={currentCountry.name}
            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          />
        </div>
        {!isCollapsed && <span className="text-sm">{currentCountry.name}</span>}
        <span
          className={`ml-auto transform ${
            isCollapsed ? 'text-[10px]' : 'text-sm'
          } transition-transform duration-200 ${isOpen ? 'rotate-180' : ''}`}
        >
          <ChevronDown size={20} />
        </span>
      </button>

      {/* Dropdown menu */}
      {isOpen && (
        <div
          ref={dropdownRef}
          className={`${
            isMobile ? 'w-[140px] text-[12px]' : 'w-[160px]'
          } fixed z-50 ${
            isCollapsed
              ? isMobile
                ? '!mr-28 mt-6'
                : '-mt-[230px] ml-24'
              : isMobile
              ? ''
              : '-mt-[230px] md:ml-[128px] xmd:ml-[280px] lg:ml-[300px]'
          } bg-[#1B2436] rounded-xl py-2 shadow-lg border-[1px] border-[#414B61] max-h-[230px] overflow-y-auto`}
        >
          {COUNTRIES.map((country) => (
            <button
              key={country.id}
              onClick={() => handleCountrySelect(country)}
              className={`w-full flex items-center ${
                country.id === 5 ? '' : 'border-b border-[#414B61]'
              } space-x-2 text-[12px]  px-4 py-2 text-white hover:bg-gray-700 focus:outline-none ${
                selectedCountry.id === country.id ? 'bg-gray-700' : ''
              }`}
            >
              <div
               className='w-[18px] h-[18px] rounded-full overflow-hidden'
              >
                <img
                  src={countryImages[country.code]}
                  alt={country.name}
                  className='w-full h-full  object-cover'
                />
              </div>
              <span className="text-[12px]">{country.name}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default CountrySelector;